function getDistance(touches: TouchList) {
    const x0 = touches[0].clientX;
    const y0 = touches[0].clientY;
    const x1 = touches[1].clientX;
    const y1 = touches[1].clientY;
    return ((x1 - x0) ** 2 + (y1 - y0) ** 2) ** 0.5;
}

function getCenter(touches: TouchList) {
    const x0 = touches[0].clientX;
    const y0 = touches[0].clientY;
    const x1 = touches[1].clientX;
    const y1 = touches[1].clientY;
    return [(x0 + x1) / 2, (y0 + y1) / 2];
}

function getCenterRelative(e: TouchEvent, img: HTMLElement) {
    const rect = img.getBoundingClientRect();
    const centerY = (rect.top + rect.bottom) / 2;
    const centerX = (rect.left + rect.right) / 2;
    // const centerY = rect.top;
    // const centerX = rect.left;
    console.log([...e.touches].map(t => t.clientY), centerY, e.currentTarget);
    const sum = [...e.touches].map(touch => ({
        x: touch.clientX - centerX,
        y: touch.clientY - centerY,
    })).reduce((p1, p2) => ({ x: p1.x + p2.x, y: p1.y + p2.y }), { x: 0, y: 0 })
    return [
        sum.x / e.touches.length,
        sum.y / e.touches.length,
    ]
}

export function initiatePinchZoom(e: TouchEvent) {
    const el = e.currentTarget as HTMLDivElement;
    // const img = (e.target as HTMLElement).closest(".img,img") as HTMLElement | null;
    const img = (e.target as HTMLElement).querySelector(".main-img") as HTMLDivElement;
    if (!img) return;

    const initialPos = getCenterRelative(e, img);
    console.log(initialPos);

    const initialDistance = getDistance(e.touches);
    const initialCenter = getCenter(e.touches);

    el.addEventListener("touchend", tidyUp);
    el.addEventListener("touchmove", onMove);

    function onMove(e: TouchEvent) {
        const currentDistance = getDistance(e.touches);
        const zoom = currentDistance / initialDistance;

        const currentCenter = getCenter(e.touches);
        const offset = [currentCenter[0] - initialCenter[0], currentCenter[1] - initialCenter[1]];

        img.style.transform = ""
            + `translate(${offset[0]}px, ${offset[1]}px)`
            + `translate(${initialPos[0]}px, ${initialPos[1]}px)`
            + `scale(${zoom}) `
            + `translate(${-initialPos[0]}px, ${-initialPos[1]}px) `

    }

    function tidyUp() {
        img.style.transform = "";
        el.removeEventListener("touchend", tidyUp);
        el.removeEventListener("touchmove", onMove);
    }
}
