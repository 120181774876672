import { checkBlobs, useFiles } from "../files/filesStore";
import exifr from "exifr";
import axios from "axios";
import { createAsyncQueue } from "../utils/async-queue";
import { processInWorker } from "./imageProcessingWorker";
import { extractStillImage } from "./videoStillImageExtraction";
import { Metadata, putFile } from "../api/api";
import { getCurrentToken } from "../auth/authStore";
import { logOut } from "../api/fetchWithAuth";
import { useUploadProgress } from "./uploadProgressStore";
import * as db from "./localDb";


async function hashBlob(blob: Blob) {
    const data = await blob.arrayBuffer();
    const hashArray = await crypto.subtle.digest("SHA-256", data);
    const hash = [...new Uint8Array(hashArray)].map(x => x.toString(16).padStart(2, "0")).join("");
    return hash;
}

interface VideoProps {
    creation_time?: string
    duration?: number
    comment?: string
}
interface VideoPropExtractor {
    path: string[],
    convert?: (input: string) => any
}
const extractableProps: Record<keyof VideoProps, VideoPropExtractor> = {
    creation_time: { path: ["format", "tags", "creation_time"] },
    duration: { path: ["format", "duration"], convert: parseFloat },
    comment: { path: ["format", "tags", "comment"] }
}

/** Extracts properties from FFMPEG's `-print_format json` output to a more useful structure. */
function extractVideoProps(input: any) {
    const result: VideoProps = {};
    for (const [name, { path, convert }] of Object.entries(extractableProps)) {
        let current = input;
        for (const segment of path) {
            current = current[segment];
        }
        result[name as keyof VideoProps] = convert ? convert(current) : current;
    }
}

type QueueItem = { hash: string; blob: Blob; link: string; callback?: (progress: number) => void };
const queues = {
    thumbnail: createAsyncQueue(uploadBlob, 100),
    preview: createAsyncQueue(uploadBlob, 10),
    main: createAsyncQueue(uploadBlob, 5),
};

async function uploadBlob({ hash, blob, link, callback }: QueueItem) {
    const files = useFiles.getState();
    const token = await getCurrentToken();
    const result = await axios.put(link, blob, {
        headers: {
            "Content-Type": "",
            "Authorization": `Bearer ${token}`
        },
        onUploadProgress({ progress }) {
            if (progress) {
                files.setUploadProgress(hash, progress);
                callback?.(progress);
            }
        },
        validateStatus: null,
    });
    if (result.status === 401) {
        logOut();
        return;
    }
    if (result.status >= 400) {
        throw new Error(`Uploading failed: ${result.status} ${result.statusText}`);
    }

    files.setUploaded(hash);
}
const imageExtensions = ["jpg", "jpeg", "jfif", "pjpeg", "pjp", "png"];
const videoExtensions = ["mov", "mp4"];
const legalExtensions = [...imageExtensions, ...videoExtensions];
const legalFileRegex = new RegExp(`(.*)\.(${legalExtensions.join("|")})$`, "i");
async function _processAndUpload(input: UploadInput) {
    const { blob, name: fileName, lastModified, event } = input;
    const { setProgress, finish } = useUploadProgress.getState();

    const match = legalFileRegex.exec(fileName);
    if (!match) {
        setProgress(fileName, "Unsupported file format. Only these extensions are supported: " + legalExtensions.join(", "));
        new Promise(r => setTimeout(r, 5000)).then(() => finish(fileName));
        return;
    }

    setProgress(fileName, "Hashing...");
    const mainHash = await hashBlob(blob);

    const [_, baseName, ext] = match;
    const name = `${baseName}.${mainHash}.${ext}`;

    const store = useFiles.getState();

    const existingFile = store.files[name];
    if (existingFile) {
        // The file exists already, so we might not have to upload it again.
        // However, we should check if all blobs uploaded successfully last time.
        const blobs = Object.values(existingFile.blobs).filter(x => x !== null);
        const availability = await checkBlobs(blobs);
        if (Object.values(availability).every(x => x)) {
            setProgress(fileName, `Already exists: '${name}'`);
            new Promise(r => setTimeout(r, 5000)).then(() => finish(fileName));
            return;
        }
    }

    let imageBlob: Blob = blob;
    let exifMeta: any = undefined;
    const isVideo = videoExtensions.includes(ext.toLowerCase());
    if (isVideo) {
        setProgress(fileName, "Extracting still image from video...");
        const stillImage = await extractStillImage(blob);
        exifMeta = stillImage.meta;
        imageBlob = stillImage.blob;
    }

    setProgress(fileName, "Rescaling...");
    let { preview, thumbnail, width, height } = await processInWorker(imageBlob);

    let previewIsVideo = false;
    if (electronMain && isVideo) {
        setProgress(fileName, "Transcoding...");
        await electronMain.ffmpegDownscale(await blob.arrayBuffer()).then(downscaled => {
            preview = { blob: new Blob([downscaled]), width: 1920, height: 1080 };
            previewIsVideo = true;
        }).catch((error) => {
            console.error("Could not use FFMPEG for downscaling:", error);
            console.log("Falling back to just using the image preview.");
        });
    }

    setProgress(fileName, "Hashing preview...");
    const previewHash = await hashBlob(preview.blob);

    setProgress(fileName, "Hashing thumbnail...");
    const thumbnailHash = await hashBlob(thumbnail.blob);

    const meta: Metadata = {
        size: blob.size,
        width, height,
        previewSize: preview.blob.size,
        previewWidth: preview.width,
        previewHeight: preview.height,
        thumbnailSize: thumbnail.blob.size,
        thumbnailWidth: thumbnail.width,
        thumbnailHeight: thumbnail.height,
        originalName: fileName,
        date: new Date(lastModified).toISOString(),
        previewIsVideo,
        extracted: {
            lastModified: new Date(lastModified).toISOString(),
        }
    };

    const exifOptions = {
        exif: true,
        ifd0: {},
        gps: true,
        tiff: true,
        ihdr: true,
        mergeOutput: true,
        sanitize: false,
        reviveValues: false,
    };
    if (!isVideo) {
        setProgress(fileName, "Extracting metadata...");
        exifMeta = await exifr.parse(blob, exifOptions);
    }

    if (exifMeta) {
        for (let [k, v] of Object.entries(exifMeta)) {
            if (v instanceof Date)
                v = v.toISOString();
            if (typeof v !== "number" && typeof v !== "string") continue;
            if (meta.extracted![k] !== undefined) continue;
            meta.extracted![k] = v;
        }

        meta.date =
            tryGetDate("DateTimeOriginal") ??
            tryGetDate("CreateDate") ??
            tryGetDate("Creation Time") ??
            tryGetDate("ModifyDate") ??
            meta.date;

        function tryGetDate(key: string) {
            const data = meta.extracted![key];
            if (typeof data !== "string") return;

            const exifDateRegex = /^(\d\d\d\d):(\d\d):(\d\d) (\d\d):(\d\d):(\d\d)$/;
            const match = exifDateRegex.exec(data);
            if (match) {
                const [_, YYYY, MM, DD, hh, mm, ss] = match;
                return new Date(`${YYYY}-${MM}-${DD}T${hh}:${mm}:${ss}`).toISOString();
            }
        }
    }

    if (electronMain && isVideo) {
        // TODO: catch errors
        const metadata = await electronMain.ffmpegGetMetadata(await blob.arrayBuffer());
        Object.assign(meta.extracted!, extractVideoProps(metadata));
        meta.date = (meta.extracted!.creation_time as string) ?? meta.date;
    }

    // The upload itself is intentionally not awaited, but dispatched instead.
    // This means that it is not bound to the same queue as the preparation work.
    return {
        uploadPromise: uploadFile({
            blobs: {
                main: {
                    blob,
                    hash: mainHash,
                },
                preview: {
                    blob: preview.blob,
                    hash: previewHash,
                },
                thumbnail: {
                    blob: thumbnail.blob,
                    hash: thumbnailHash,
                }
            },
            displayName: fileName,
            meta,
            name,
            event
        })
    }
}

interface UploadInput {
    blob: Blob
    name: string
    lastModified: number
    event?: string
    path?: string
}

const processingQueue = createAsyncQueue(_processAndUpload, navigator.hardwareConcurrency);
export function processAndUpload(input: UploadInput) {
    const { addItem, setProgress } = useUploadProgress.getState();

    if (input.path) {
        db.putFiles([{ path: input.path }])
    }

    addItem(input.name, input.blob.size);
    const reportError = (phase: string, e: any) => {
        setProgress(input.name, phase)
        console.error(phase, input.name, e);
    }
    processingQueue.enqueue(input)
        .then(result => result?.uploadPromise
            .catch((e) => reportError("Error while uploading", e)))
        .catch((e) => reportError("Error while processing", e))
        .then(() => {
            if (input.path)
                db.removeFiles([input.path])
        })

}



interface ProcessedFile {
    /** The raw file name, e.g. IMG1341.JPG */
    displayName: string,
    /** The name including the hash, e.g. IMG1341.dc27834fa23.JPG */
    name: string,
    meta: any, //TODO
    blobs: {
        [Key in "main" | "preview" | "thumbnail"]: {
            blob: Blob,
            hash: string,
        }
    }
    event?: string
}

async function uploadFile(file: ProcessedFile) {
    const { name, displayName, meta, blobs: { main, preview, thumbnail }, event } = file;

    const { setProgress, finish } = useUploadProgress.getState();
    const store = useFiles.getState();

    setProgress(displayName, "Uploading metadata...");
    const blobLinks = await putFile(name, {
        blobs: {
            main: main.hash,
            preview: preview.hash,
            thumbnail: thumbnail.hash,
        },
        meta
    }, { event });

    store.uploadFile({
        name,
        blobs: {
            main: { hash: main.hash, url: URL.createObjectURL(main.blob) },
            preview: { hash: preview.hash, url: URL.createObjectURL(preview.blob) },
            thumbnail: { hash: thumbnail.hash, url: URL.createObjectURL(thumbnail.blob) },
        },
        meta,
        event
    });

    for (const name of ["thumbnail", "preview", "main"] as const) {
        const { hash, blob } = file.blobs[name];
        const link = blobLinks[name];
        if (!link) { // means that blob exists already
            console.log(`${displayName}: Blob ${name} is already uploaded, skipping.`);
            store.setUploaded(hash);
            continue;
        }
        const phase = `Uploading ${name}...`;
        setProgress(displayName, phase);
        await queues[name].enqueue({
            blob, hash, link,
            callback: progress => setProgress(displayName, phase, progress)
        });
    }

    setProgress(displayName, "Done.");
    await new Promise(r => setTimeout(r, 5000));
    finish(displayName);
}
