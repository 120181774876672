import React, { useEffect, useRef, useState } from "react";
import "./Preview.scss";
import classNames from "classnames";
import { Badge } from "react-bootstrap";
import { useLocation } from "wouter";
import { useSearch } from "wouter";
import { useFiles } from "../files/filesStore";
import { MetadataSlim, useGetDetails } from "../api/api";
import { SizeDisplay } from "../upload/SizeDisplay";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faCloudDownload, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { toggleSelected, useSelection } from "../tools/selectionStore";
import { initiateSwipe } from "./gestures/swipe";
import { initiatePinchZoom } from "./gestures/pinchZoom";

let setLocation: (to: string, options?: { replace?: boolean }) => void;

export const getPreview = () => {
    return new URLSearchParams(window.location.search).get("preview");
}
export const setPreview = (file: string) => {
    const targetUrl = new URL(window.location.href);
    targetUrl.searchParams.set("preview", file);
    setLocation(targetUrl.toString());
}
export const closePreview = () => {
    const targetUrl = new URL(window.location.href);
    targetUrl.searchParams.delete("preview");
    setLocation(targetUrl.toString());
}

export function shiftFocus(delta: number) {
    const fileList = Object.keys(useFiles.getState().files);
    const selected = getPreview();
    if (!selected) return;
    const currentIndex = fileList.indexOf(selected);
    const next = fileList[currentIndex + delta];
    if (next)
        setPreview(next);
}
function setFocus(index: number) {
    const fileList = Object.keys(useFiles.getState().files);
    const next = fileList.at(index);
    if (next)
        setPreview(next);
}

export default function PreviewScreen() {
    const search = useSearch();
    const file = new URLSearchParams(search).get("preview");
    const displayedFiles = useFiles(f => {
        const fileList = Object.keys(f.files);
        const currentIndex = fileList.indexOf(file!);
        const prev = fileList[currentIndex - 1];
        const next = fileList[currentIndex + 1];
        if (currentIndex === -1) return [];
        return [prev, file, next];
    });

    const [location, _setLocation] = useLocation();
    setLocation = _setLocation;


    useEffect(() => {
        if (!file) return;

        function onKeyPress(e: KeyboardEvent) {
            if (e.key === "ArrowLeft" || e.key === "a") shiftFocus(-1);
            else if (e.key === "ArrowRight" || e.key === "d") shiftFocus(+1);
            else if (e.key === "Home") setFocus(0);
            else if (e.key === "End") setFocus(-1);
            else if (e.key === "Escape") closePreview();
            else if (e.key === "ArrowDown") file && download(file);
            else if (e.key === " ") file && toggleSelected(file);
            // TODO: shift-select
            else return;
            e.preventDefault();
        }
        document.addEventListener("keydown", onKeyPress);

        function onScroll(e: WheelEvent) {
            if ((e.target as HTMLElement | null)?.matches?.(".meta *")) return;
            if (e.deltaY < 0) shiftFocus(-1);
            else if (e.deltaY > 0) shiftFocus(+1);
            else return;
            e.preventDefault();
        }
        document.addEventListener("wheel", onScroll, { passive: false });

        document.body.style.touchAction = "none";

        return () => {
            document.body.style.touchAction = "";
            document.removeEventListener("keydown", onKeyPress);
            document.removeEventListener("wheel", onScroll);
        }
    }, [file]);


    if (file === null)
        return <></>

    return <div className="preview-screen"
        onTouchStart={e => {
            if (e.touches.length === 2)
                initiatePinchZoom(e.nativeEvent);
            else if (e.touches.length === 1)
                initiateSwipe(e.nativeEvent);
        }}
    >
        {displayedFiles.map(file => file ? <Preview file={file} key={file} /> : <div />)}
    </div>
}

function download(file: string) {
    const { files, blobs } = useFiles.getState();
    const a = document.createElement("a");
    a.href = blobs[files[file].blobs.main].url;
    a.download = files[file].meta.originalName;
    a.click();
}

function Preview(props: { file: string }) {
    const { meta, tags, event } = useFiles(state => state.files[props.file]);
    const blobUrl = useFiles(state => {
        const blobs = state.files[props.file].blobs;
        const hash = blobs.preview ?? blobs.main
        return state.blobs[hash].url;
    });

    const thumbnailUrl = useFiles(state => {
        const blobs = state.files[props.file].blobs;
        if (blobs.thumbnail)
            return state.blobs[blobs.thumbnail].url;
    });

    const isSelected = useSelection(s => s.selection.includes(props.file));

    const [img, setImg] = useState<string>();
    useEffect(() => {
        setImg(undefined);
        if (meta.previewIsVideo) return;

        const ac = new AbortController();
        let _blobUrl: string;
        (async function () {
            const response = await fetch(blobUrl, { signal: ac.signal }).catch(e => null);
            if (!response?.ok) return;

            const blob = await response.blob();
            if (ac.signal.aborted) return;

            _blobUrl = URL.createObjectURL(blob);
            setImg(_blobUrl);
        })().catch(() => { })
        return () => {
            ac.abort();
            if (_blobUrl) URL.revokeObjectURL(_blobUrl);
        }
    }, [blobUrl, setImg])

    const [metaVisible, setMetaVisible] = useState(false);

    const opened = useRef(performance.now());

    return <div className="preview"
        onClick={(e) => {
            if (e.target !== e.currentTarget) return;
            const isOpenFor = performance.now() - opened.current;
            if (isOpenFor < 300) return;
            closePreview();
        }}
    >
        {meta.previewIsVideo ? <video
            src={blobUrl}
            onLoadedData={e => e.currentTarget.play()}
            controls
        /> : <>
            <div className="img" title={props.file} style={{
                background: `center / contain no-repeat url(${thumbnailUrl})`,
                width: `min(${meta.previewWidth}px, 100%)`,
                height: `min(${meta.previewHeight}px, 100%)`,
            }} />
            {img && <div className="img main-img" title={props.file} style={{
                background: `center / contain no-repeat url(${img})`,
                width: `min(${meta.previewWidth}px, 100%)`,
                height: `min(${meta.previewHeight}px, 100%)`,
            }} />}
        </>}
        <div className="title">
            <div className="preview-title-row" >
                <h3>{meta.originalName}</h3>
                <FontAwesomeIcon size="2x" icon={faInfoCircle} onClick={() => setMetaVisible(x => !x)} />
                <FontAwesomeIcon size="2x" icon={faCloudDownload} onClick={() => download(props.file)} />
                <FontAwesomeIcon size="2x" color={isSelected ? "#76d8ff" : "lightgray"} icon={faCheckCircle} onClick={() => toggleSelected(props.file)} />
            </div>
            <div className="preview-meta-row">
                <div>{new Date(meta.date).toLocaleString()}</div>
                <div>{meta.width} {"\u00d7"} {meta.height} px</div>
                <div>{Math.round(meta.size / 2 ** 20 * 10) / 10} MiB</div>
            </div>
            <div className="preview-meta-row">
                {!!tags?.length && <div>Tags:&ensp;{tags.map(tag => <Badge key={tag}>{tag}</Badge>)}</div>}
                {event && <div>Event: {event}</div>}
            </div>
        </div>
        <div className={classNames("meta", { "visible": metaVisible })}>
            <div className="clickable-text" onClick={e => {
                e.stopPropagation()
                setMetaVisible(false)
            }}>Close</div>
            <div className="inner">
                <MetaDetails file={props.file} meta={meta} visible={metaVisible} />
            </div>
        </div>
    </div>
}

function MetaDetails(props: { file: string, meta: MetadataSlim, visible: boolean }) {
    const { file, meta, visible } = props;
    return <>
        <div>
            <h5>{meta.originalName}</h5>

            {meta.date}
        </div>
        <div>
            Full file: {meta.width}{"\u00d7"}{meta.height}, <SizeDisplay bytes={meta.size} />
        </div>
        <div>
            Preview: {meta.previewWidth}{"\u00d7"}{meta.previewHeight}, <SizeDisplay bytes={meta.previewSize} />
        </div>
        <div>
            Thumbnail: {meta.thumbnailWidth}{"\u00d7"}{meta.thumbnailHeight}, <SizeDisplay bytes={meta.thumbnailSize} />
        </div>
        {visible && <MetaDetailsExtracted file={file} />}
    </>
}

function MetaDetailsExtracted(props: { file: string }) {
    const details = useGetDetails(props.file);
    return <div>
        Extracted information:
        {details.data ? <>
            {Object.entries(details.data.meta.extracted ?? {}).map(([k, v]) => <div key={k}><b>{k}:</b> {v}</div>)}
        </> : "Loading..."}
    </div>
}