import { useEffect } from "react";
import { useAuth } from "./authStore";
import { navigate } from "wouter/use-browser-location";
import { Button } from "react-bootstrap";
import { LogoutButton } from "./LogoutButton";
import React from "react";

export function LoginErrorPage() {
    const error = useAuth(s => s.sessionError);
    useEffect(() => {
        if (!error)
            navigate("/");
    }, [!error])

    return <div>
        There was an error while logging in automatically:
        <pre>
            {error}
        </pre>
        <Button onClick={() => window.location.reload()}>Try again</Button>
        <LogoutButton />
    </div>
}