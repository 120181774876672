import Axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { getCurrentToken, useAuth } from '../auth/authStore';

export const AXIOS_INSTANCE = Axios.create();

export function logOut() {
    const currentUrl = window.location.href;
    const loginUrl = new URL(currentUrl);
    loginUrl.pathname = "/login"
    loginUrl.search = "";
    loginUrl.searchParams.set("return_url", currentUrl.slice(loginUrl.origin.length));

    useAuth.getState().logOut();
    window.location.replace(loginUrl);
}

export const fetchWithAuth = <T>(
    config: AxiosRequestConfig,
    options?: AxiosRequestConfig,
): Promise<T> => {
    const source = Axios.CancelToken.source();
    const promise = doTheRequest();
    // @ts-ignore
    promise.cancel = () => {
        source.cancel('Query was cancelled');
    };
    return promise;

    async function doTheRequest() {
        const token = await getCurrentToken();
        const Authorization = token && `Bearer ${token}`;
        const response = await AXIOS_INSTANCE({
            ...config,
            ...options,
            headers: {
                Authorization,
                ...(options?.headers)
            },
            cancelToken: source.token,
            validateStatus(status) {
                if (status < 400)
                    return true;
                if (status === 401) {
                    logOut();
                }
                return false;
            }
        });

        return response.data;
    }
};

export type ErrorType<Error> = AxiosError<Error>;

export type BodyType<BodyData> = BodyData;
