import { shiftFocus } from "../Preview";
import { initiatePinchZoom } from "./pinchZoom";

export function initiateSwipe(e: TouchEvent) {
    const el = document.querySelector(".preview-screen") as HTMLElement;
    el.addEventListener("touchmove", touchMove);
    el.addEventListener("touchend", touchEnd);
    el.addEventListener("touchcancel", tidyUp);

    const touchStart = e.touches[0];
    const tStart = e.timeStamp;

    function touchMove(e: TouchEvent) {
        if (e.touches.length !== 1) {
            // switch to pinch-zoom handling
            tidyUp();
            initiatePinchZoom(e);
            return;
        }
        const touch = e.changedTouches[0];
        if (touch.identifier !== touchStart.identifier) return;
        const dx = touch.screenX - touchStart.screenX;
        el.style.transform = `translateX(${dx}px)`;
        e.preventDefault();
    }

    async function touchEnd(e: TouchEvent) {
        tidyUp();
        const touchEnd = e.changedTouches[0];
        if (touchEnd.identifier !== touchStart.identifier) return;
        const dx = touchStart.screenX - touchEnd.screenX;
        const dt = e.timeStamp - tStart;
        //TODO: actually do the swipes
        if (dx > window.innerWidth * 0.1) {
            await animate(1);
        }
        if (dx < -window.innerWidth * 0.1) {
            await animate(-1);
        }
        async function animate(direction: 1 | -1) {
            const duration = Math.min(dt, 100);
            // By delaying the end of the animation a bit, we can avoid the rendering of it to overlap with any
            // re-layout or react work that is caused by actually changing the focus.
            const postAnimationDelay = 300;
            el.getAnimations().forEach(a => a.cancel());
            const target = `translateX(${-100 * direction}vw)`;
            const animOut = el.animate([
                { offset: 0, transform: `translateX(${-dx}px)` },
                { offset: duration / (duration + postAnimationDelay), transform: target },
                { offset: 1, transform: target }
            ], {
                duration: duration + postAnimationDelay,
                easing: "linear"
            });
            const animationFinished = await new Promise<boolean>(resolve => {
                animOut.onfinish = () => resolve(true);
                animOut.oncancel = () => resolve(false);
            });
            await animationFinished;
            shiftFocus(direction);
        }
    }

    function tidyUp() {
        el.style.transform = `unset`;
        el.removeEventListener("touchmove", touchMove);
        el.removeEventListener("touchend", touchEnd);
        el.removeEventListener("touchcancel", tidyUp);
    }
}
