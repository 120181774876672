import React, { useEffect } from "react";
import { refreshToken, useAuth } from "./authStore";

export function SessionRefresher() {
    const sessionToken = useAuth(x => x.sessionToken);
    useEffect(() => {
        if (!sessionToken) return;

        refreshToken();
        const interval = setInterval(refreshToken, 10_000);

        return () => clearInterval(interval);
    }, [sessionToken]);
    return <></>;
}
