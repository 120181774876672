import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"

import "bootstrap/dist/css/bootstrap.min.css"
import { Route, Switch } from "wouter";
import SharedApp from "./share/SharedApp";

import LoginPage from "./auth/LoginPage";
import { LoginErrorPage } from "./auth/LoginErrorPage";


// Prevent "electronMain is not defined" type of errors
window.electronMain ??= undefined;

const container = document.getElementById("app");
const root = createRoot(container!)
root.render(<Main />);

function Main() {
    return <DndProvider backend={HTML5Backend}>
        <Switch>
            <Route path="/login-error">
                <LoginErrorPage />
            </Route>
            <Route path="/login">
                <LoginPage />
            </Route>
            <Route path="/share/:token">
                <SharedApp />
            </Route>
            <Route>
                <App />
            </Route>
        </Switch>
    </DndProvider>
}
