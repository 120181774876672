import { create } from "zustand";
import { persist } from "zustand/middleware";
import { createSession, getSessionAccessToken } from "../api/api";
import uaparse from "ua-parser-js"
import * as jose from "jose";
import { logOut } from "../api/fetchWithAuth";
import { navigate } from "wouter/use-browser-location";

export interface AuthState {
    sessionToken?: string,
    accessToken?: string,
    user?: string,
    sessionError?: string,
    logIn: (token: string) => void,
    createSession: () => Promise<void>,
    logOut: () => void,
}

export const useAuth = create<AuthState>()(
    persist(
        (set) => ({
            logIn: (accessToken: string) => {
                set({ accessToken, user: jose.decodeJwt(accessToken).sub })
            },
            createSession: async () => {
                const ua = uaparse();
                const isPWA = !window.matchMedia('(display-mode: browser)').matches;
                const descriptionParts = [ua.browser.name, "on", ua.os.name, ua.os.version, isPWA && "(PWA)"];
                const description = descriptionParts.filter(x => x).join(" ");
                set({ sessionToken: await createSession(description) })
            },
            logOut: () => {
                set({ accessToken: undefined, sessionToken: undefined })
            },
        }),
        { name: "auth" }
    )
);
useAuth.setState({ sessionError: undefined });

function isFresh(token: string | undefined) {
    if (!token) return false;
    const payload = jose.decodeJwt(token);
    const expiration = payload.exp! * 1000;
    const now = Date.now();
    return expiration - now > 30_000;
}

async function _refreshToken() {
    const { sessionToken, accessToken: existingAccessToken } = useAuth.getState();
    if (isFresh(existingAccessToken)) return;
    if (!sessionToken) return;

    // We need to use a "manual" fetch here, since this request must be unauthenticated.
    const result = await fetch("/api/auth/sessions/sessions/get_token", {
        method: "POST", body: sessionToken
    });

    if (result.status === 401) {
        logOut();
    }
    if (result.status >= 400) {
        useAuth.setState({
            sessionError: `${result.status} ${result.statusText}\n${await result.text()}`
        });
        navigate("/login-error")
        return;
    }

    const token = await result.json();

    useAuth.getState().logIn(token);
}

let refreshTokenPromise: Promise<void> = _refreshToken();
export function refreshToken() {
    refreshTokenPromise = _refreshToken();
    return refreshTokenPromise;
}

export async function getCurrentToken() {
    await refreshTokenPromise;
    return useAuth.getState().accessToken;
}
